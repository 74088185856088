import React, { Suspense, lazy, useEffect, useState } from 'react'
import './app.scss'
import { Route, Routes } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Loader from '../PreLoader/Loader'
import PagesPlaceholder from '../Placeholder/PagesPlaceholder'

const RingLoader = lazy(() => import('../PreLoader/RingLoader'))
const PagesView = lazy(() => import('../../pages/PagesView/PagesView'))
const NotFound = lazy(() => import('../NotFound/NotFound'))
const View = lazy(() => import('../../pages/View/View'))
const DepartmentView = lazy(() =>
  import('../../pages/Department/DepartmentView')
)
const EmployeeView = lazy(() => import('../../pages/EmployeeView/EmployeeView'))
const FaculityView = lazy(() => import('../../pages/Faculity/FaculityView'))
const SubCategory = lazy(() => import('../../pages/SubCategory/SubCategory'))
const Category = lazy(() => import('../../pages/Category/Category'))
const Sitemap = lazy(() => import('../../pages/Sitemap/Sitemap'))
const Search = lazy(() => import('../../pages/Search/Search'))
const Flag = lazy(() => import('../../pages/Emblems/Flag'))
const Emblem = lazy(() => import('../../pages/Emblems/Emblem'))
const Music = lazy(() => import('../../pages/Emblems/Music'))
const Register = lazy(() => import('../../pages/Profile/Register'))
const Login = lazy(() => import('../../pages/Profile/Login'))
const Profile = lazy(() => import('../../pages/Profile/Profile'))
const Edit = lazy(() => import('../../pages/Profile/Edit'))
const Editimg = lazy(() => import('../../pages/Profile/Editimg'))
const Layout = lazy(() => import('../Layout/Layout'))
const Home = lazy(() => import('../Home/Home'))

export default function App() {
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
    document.title = t('app_name')
  }, [t])

  return loading ? (
    <RingLoader />
  ) : (
    <Routes>
      <Route
        path='/'
        element={
          <Suspense fallback={<Loader />}>
            <Layout />
          </Suspense>
        }
      >
        <Route
          index
          element={
            <Suspense fallback={<Loader />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={'/pagesView/:link'}
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <PagesView />
            </Suspense>
          }
        />
        <Route
          path={'/pagesView/view/:link'}
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <View />
            </Suspense>
          }
        />
        <Route
          path='/pagesView/departmentView/:link'
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <DepartmentView />
            </Suspense>
          }
        />
        <Route
          path='/pagesView/faculityView/:link'
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <FaculityView />
            </Suspense>
          }
        />
        <Route
          path='/subCategory/:link'
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <SubCategory />
            </Suspense>
          }
        />
        <Route
          path='/category/:link'
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <Category />
            </Suspense>
          }
        />
        <Route
          path='/employeeView/:link'
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <EmployeeView />
            </Suspense>
          }
        />
        <Route
          path='/sitemap'
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <Sitemap />
            </Suspense>
          }
        />
        <Route
          path='/flag'
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <Flag />
            </Suspense>
          }
        />
        <Route
          path='/emblem'
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <Emblem />
            </Suspense>
          }
        />
        <Route
          path='/music'
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <Music />
            </Suspense>
          }
        />
        <Route
          path='/signup'
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <Register />
            </Suspense>
          }
        />
        <Route
          path='/login'
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path='/profile'
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <Profile />
            </Suspense>
          }
        />
        <Route
          path='/profile/edit/:user_id'
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <Edit />
            </Suspense>
          }
        />
        <Route
          path='/profile/editimg/:user_id'
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <Editimg />
            </Suspense>
          }
        />
        <Route
          path='/search/:search'
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <Search />
            </Suspense>
          }
        />
        <Route
          path='*'
          element={
            <Suspense fallback={<PagesPlaceholder />}>
              <NotFound />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  )
}
