import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "../slice/home";
import sliderReducer from "../slice/sliderSlice";
import newsReducer from "../slice/newsSlice";
import menuReducer from "../slice/menuSlice";
import adsReducer from "../slice/adsSlice";
import employeeReducer from "../slice/employeeSlice";
import galleryReducer from "../slice/gallerySlice";
import pageviewReducer from "../slice/pageviewSlice";
import moredataReducer from "../slice/moredataSlice";
import moredepartmentReducer from "../slice/moreDepartmentSlice";
import employeeViewReducer from "../slice/employeeViewSlice";
import mostViewReducer from "../slice/mostViewSlice";
import subCategoryReducer from "../slice/subCategorySlice";
import categoryReducer from "../slice/categorySlice";
import searchReducer from "../slice/searchSlice";
import multimenuReducer from "../slice/multimenuSlice";
import authReducer from "../slice/authSlice";
import profileReducer from "../slice/profileSlice";
import getProfileReducer from "../slice/getProfile";
import navbarReducer from "../slice/navbar";
import activityReducer from "../slice/activitySlice";
import faculityReducer from "../slice/faculitySlice";
import departmentReducer from "../slice/departmentSlice";

export default configureStore({
  reducer: {
    home: homeReducer,
    slider: sliderReducer,
    news: newsReducer,
    menus: menuReducer,
    ads: adsReducer,
    employee: employeeReducer,
    gallery: galleryReducer,
    pageview: pageviewReducer,
    moredata: moredataReducer,
    moredepertment: moredepartmentReducer,
    employeeview: employeeViewReducer,
    mostview: mostViewReducer,
    subcategory: subCategoryReducer,
    category: categoryReducer,
    search: searchReducer,
    multimenu: multimenuReducer,
    auth: authReducer,
    profile: profileReducer,
    getprofile: getProfileReducer,
    navbar: navbarReducer,
    activity: activityReducer,
    faculity: faculityReducer,
    department: departmentReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});
