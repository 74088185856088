import { createSlice } from "@reduxjs/toolkit";
import axios from "../services/api";

const initialState = {
  moreData: null,
  loading: false,
  error: null,
};

const moredataSlice = createSlice({
  name: "moredata",
  initialState,
  reducers: {
    fetchDataStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDataSuccess: (state, action) => {
      state.loading = false;
      state.moreData = action.payload;
    },
    fetchDataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure } =
  moredataSlice.actions;

export const fetchMoredata = (link) => async (dispatch) => {
  try {
    dispatch(fetchDataStart());
    const response = await axios.get("/pages/readmore?link=" + link);
    dispatch(fetchDataSuccess(response.data));
  } catch (error) {
    dispatch(fetchDataFailure(error.message));
  }
};

export default moredataSlice.reducer;
