import { createSlice } from '@reduxjs/toolkit'

export const homeSlice = createSlice({
  name: 'home',
  initialState: { scroll: false },
  reducers: {
    scrollUp: state => {
      if (window.scrollY >= 350) {
        state.scroll = true
      } else {
        state.scroll = false
      }
    },
    scrollTop: () => {
      window.scrollTo({
        top: 0,
      })
    },
  },
})

export const { scrollUp, scrollTop } = homeSlice.actions
export default homeSlice.reducer
