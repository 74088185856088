export const setItem = (item, value) => {
  try {
    localStorage.setItem(item, value);
  } catch (e) {
    console.log(e);
  }
};

export const getItem = (item) => {
  try {
    return localStorage.getItem(item);
  } catch (error) {
    console.log(error);
  }
};

export const removeItem = (item) => {
  try {
    localStorage.removeItem(item);
  } catch (error) {
    console.log(error);
  }
};
