import { createSlice } from "@reduxjs/toolkit";

export const navbarSlice = createSlice({
  name: "navbar",
  initialState: { nav: false },
  reducers: {
    setNavbar: (state) => {
      if (state.nav === true) {
        state.nav = false;
      } else {
        state.nav = true;
      }
    },
  },
});

export const { setNavbar } = navbarSlice.actions;
export default navbarSlice.reducer;
