import React from 'react'

export default function PagesPlaceholder() {
  return (
    <div className='w-full animate-pulse'>
      <div className='w-full py-20 bg-gray-200 dark:bg-gray-500 '>
        <div className='lg:container mx-auto flex items-center gap-4'>
          <div className='w-8 bg-gray-400 h-8 rounded-lg'></div>
          <div className='w-24 bg-gray-400 h-6 rounded-lg'></div>
          <div className='w-24 bg-gray-400 h-6 rounded-lg'></div>
          <div className='w-28 bg-gray-400 h-6 rounded-lg'></div>
        </div>
      </div>
      <div className='lg:container mx-auto mt-8 mb-20 h-96 bg-gray-200 dark:bg-gray-500 rounded-xl'></div>
    </div>
  )
}
