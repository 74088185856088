import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const getProfile = createSlice({
  name: "getprofile",
  initialState,
  reducers: {
    getProfileDataStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getProfileDataSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    getProfileDataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getProfileDataStart,
  getProfileDataSuccess,
  getProfileDataFailure,
} = getProfile.actions;

export default getProfile.reducer;
