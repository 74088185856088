import { createSlice } from "@reduxjs/toolkit";
import axios from "../services/api";

const initialState = {
  department: null,
  loading: false,
  error: null,
};

const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    fetchDataStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDataSuccess: (state, action) => {
      state.loading = false;
      state.department = action.payload;
    },
    fetchDataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure } =
  departmentSlice.actions;

export const fetchDepartment = () => async (dispatch) => {
  try {
    dispatch(fetchDataStart());
    const response = await axios.get("/pages/department");
    dispatch(fetchDataSuccess(response.data));
  } catch (error) {
    dispatch(fetchDataFailure(error.message));
  }
};

export default departmentSlice.reducer;
