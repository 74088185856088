import { createSlice } from "@reduxjs/toolkit";
import axios from "../services/api";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const multimenuSlice = createSlice({
  name: "mutlimenu",
  initialState,
  reducers: {
    fetchDataStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDataSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchDataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure } =
  multimenuSlice.actions;

export const fetchMultiMenu = () => async (dispatch) => {
  try {
    dispatch(fetchDataStart());
    const response = await axios.get("/multi-menus");
    dispatch(fetchDataSuccess(response.data));
  } catch (error) {
    dispatch(fetchDataFailure(error.message));
  }
};

export default multimenuSlice.reducer;
